import * as UIkit from 'uikit';

document.addEventListener(
  "DOMContentLoaded",
  function () {
    UIkit.default.slider("[uk-slider]");
  },
  false
);

